import { message } from "antd";
import { getData } from "./getRtkBases";
import { userInterface } from "../types";
import config from '../config.json';
const { SERVER_URL } = config;


export const addClient = (client: userInterface, context: any) => {

    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        body: JSON.stringify({ token: localStorage.getItem('token'), client: client })
    };
    fetch(SERVER_URL + "/client/add", requestOptions).then((response) => {
        getData(context.data.rtkBases[1], context.data.users[1]);

    }).catch((e) => {
        message.error("Could not add client")
    })
}
