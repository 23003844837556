
import { Button, Form, Input, message } from 'antd';
import logo from './assets/logo.png';
import config from './config.json';
const { SERVER_URL } = config;

interface loginPageProps {
  setLoading: any,
  setLogged: any
}

const LoginPage = (props: loginPageProps) => {

  const onFinish = (values: any) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "access-control-request-headers":'content-type',
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify({ login: values.username, password: values.password })
    };
    props.setLoading(true)
    fetch(SERVER_URL + '/login', requestOptions)
      .then(response => response.json())
      .then(data => {
        props.setLoading(false)
        localStorage.setItem('token', data.value)
        props.setLogged(true)
      }
      ).catch(() => {
        props.setLoading(false)
        message.error("Wrong credentials")
      });
  };

  const onFinishFailed = () => {
    message.error("Wrong credentials")
  };
  return (<>
    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }} id="site">
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        <div id="logo" >
          <img alt="logo" style={{ width: "250px" }} src={logo} />
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>



            <Form.Item wrapperCol={{ offset: 0 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>

  </>)
}
export default LoginPage
