export interface rtkBaseInterface {
  mountpoint: string,
  lat: number,
  lon: number,
  active: number,
  updated: string,
}
export interface userInterface {
  id:number,
  name: string,
  surname: string,
  note: string,
  phone: string,
  lat: number,
  lon: number,
  status: number,
  devices: number,
  address: string,
}

export interface farmInterface {
  hash_id?: number,
  name: string,
  lat: number,
  lon: number,
  created_timestamp?: Date,
  disabled?: boolean,
}

export interface TabletDataInterface {
  hash_id: number;
  name: string;
  current_agroosa_version: string;
}

export interface VehicleDataInterface {
  hash_id: number;
  name: string;
  outside_hash_id: string;
}

export interface FarmDataInterface {
  hash_id: number;
  name: string;
  lon: number;
  lat: number;
  tablets: TabletDataInterface[];
  vehicles: VehicleDataInterface[];
}


export type dataframeCountersPackage = {
  "1005": Dataframe,
  "1077": Dataframe,
  "1087": Dataframe,
  "1097": Dataframe,
  "1127": Dataframe,
  "1230": Dataframe
}

export type stationListener = {
  mountpoint: string,
  dataframeCounters: dataframeCountersPackage,
  lastUpdatedDataframe: string, ws: any

}
export class Dataframe {
  type = ''
  count = 0
  lastLength = 0
  lastUpdate = new Date()
  constructor(type_v: string) {
    this.type = type_v
  }
  updateCount(value: number, len: number) {
    this.count = value;
    this.lastUpdate = new Date();
    this.lastLength = len
  }
}
