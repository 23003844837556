import config from '../config.json';
const { SERVER_URL } = config;


export const logout = (setLogged: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
    body: JSON.stringify({ value: localStorage.getItem('token') })
  };
  fetch(SERVER_URL + "/logout", requestOptions).then(() => {
    localStorage.removeItem('token')
    setLogged(false)
  })

}
