import { message } from "antd";
import config from '../config.json';
const { SERVER_URL } = config;


export const getData = (setRtkBases: any, setUsers: any, setLogged?: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
    body: JSON.stringify({ value: localStorage.getItem('token') })
  };
  fetch(SERVER_URL + "/rtk/all", requestOptions).then((response: any) => response.json()).then((response) => {
    setRtkBases(response)
  }).catch(() => {
    message.error("Session expired")
    localStorage.removeItem('token')
    if (setLogged) {
      setLogged(false)
    }
  })
  fetch(SERVER_URL + "/users/all", requestOptions).then((response: any) => response.json()).then((response) => {
    setUsers(response)
  }).catch(() => {
    message.error("Session expired")
    localStorage.removeItem('token')
    if (setLogged) {
      setLogged(false)
    }
  })
}

