import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { TabletDataInterface } from '../types';

interface TabletEditFormProps {
    tablet: TabletDataInterface;
    visible: boolean;
    onClose: () => void;
    onSave: (updatedTablet: TabletDataInterface) => void;
}

const TabletEditForm: React.FC<TabletEditFormProps> = ({ tablet, visible, onClose, onSave }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(tablet);
    }, [tablet, form]);

    const handleSave = () => {
        form.validateFields().then(values => {
            onSave({ ...tablet, ...values });
            onClose();
        });
    };

    return (
        <Modal
            title={`Edit Tablet: ${tablet.name}`}
            visible={visible}
            onOk={handleSave}
            onCancel={onClose}
            okText="Apply"
        >
            <Form form={form} layout="vertical" initialValues={tablet}>
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Current Version" name="current_agroosa_version">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default TabletEditForm;
