import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { VehicleDataInterface } from '../types';

interface VehicleEditFormProps {
    vehicle: VehicleDataInterface;
    visible: boolean;
    onClose: () => void;
    onSave: (updatedVehicle: VehicleDataInterface) => void;
}

const VehicleEditForm: React.FC<VehicleEditFormProps> = ({ vehicle, visible, onClose, onSave }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(vehicle);
    }, [vehicle, form]);

    const handleSave = () => {
        form.validateFields().then(values => {
            onSave({ ...vehicle, ...values });
            onClose();
        });
    };

    return (
        <Modal
            title={`Edit Vehicle: ${vehicle.name}`}
            visible={visible}
            onOk={handleSave}
            onCancel={onClose}
            okText="Apply"
        >
            <Form form={form} layout="vertical" initialValues={vehicle}>
                <Form.Item label="Name" name="name">
                    <Input />
                </Form.Item>
                {vehicle.hash_id < 0 ? (
                    <div style={{ color: 'red', marginBottom: '24px' }}>Save to see the ID</div>
                ) : (
                    <Form.Item label="Outside Hash ID" name="outside_hash_id">
                        <Input />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default VehicleEditForm;
