import { createContext, useContext, useState } from "react";
import { rtkBaseInterface, userInterface } from "./types";
const useData = () => {
    let rtkBases = useState<rtkBaseInterface[]>([]);
    let users = useState<userInterface[]>([]);

    return {
        data: {
            rtkBases: rtkBases,
            users: users
        }
    }
}
const DataSourceContext = createContext(
    {} as ReturnType<typeof useData>);

export const UseDataSourceContext = () => {
    return useContext(DataSourceContext);
};

export const DataSourceContextProvider = (props: AppContextProviderArguments) => {

    return (
        <DataSourceContext.Provider value={useData()}>
            {props.children}
        </DataSourceContext.Provider>
    );
};

interface AppContextProviderArguments {
    children: JSX.Element;
}
