import React, { useEffect, useState } from 'react';
import { Form, Input, Button, List, Spin, Modal, message, Typography } from 'antd';
import { getFarm } from '../adapter/getFarm';
import { updateFarm } from '../adapter/updateFarm';
import { deleteFarm } from '../adapter/deleteFarm';
import { userInterface } from '../types';
import { assignFarm } from '../adapter/assignFarm';
import { UseDataSourceContext } from '../DataSourceContext';
import { FarmDataInterface, TabletDataInterface, VehicleDataInterface } from '../types';
import TabletEditForm from './TabletEditForm';
import VehicleEditForm from './VehicleEditForm';
import { ExclamationCircleFilled, CopyOutlined } from "@ant-design/icons";

interface FarmEditFormProps {
    client: userInterface,
    modal: boolean,
    show: boolean,
    setShow: any,
}

const FarmEditForm: React.FC<FarmEditFormProps> = (props) => {
    const context = UseDataSourceContext();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [farmData, setFarmData] = useState<FarmDataInterface | null>(null);
    const [formInitialized, setFormInitialized] = useState(false); // Track form initialization
    const [selectedTablet, setSelectedTablet] = useState<TabletDataInterface | null>(null);
    const [selectedVehicle, setSelectedVehicle] = useState<VehicleDataInterface | null>(null);
    const [tabletFormVisible, setTabletFormVisible] = useState(false);
    const [vehicleFormVisible, setVehicleFormVisible] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await getFarm(props.client.id);
            let firstItem = data.length > 0 ? data[0] : null;
            if (firstItem) {
                setFarmData(firstItem); // Assuming data is an array and firstItem is an object
                form.setFieldsValue({
                    hash_id: firstItem.hash_id,
                    name: firstItem.name,
                    lon: firstItem.lon,
                    lat: firstItem.lat,
                    tablets: firstItem.tablets.map((tablet: any) => ({ ...tablet })), // Map tablets to match form structure
                    vehicles: firstItem.vehicles.map((vehicle: any) => ({ ...vehicle })), // Map vehicles to match form structure
                });
                setFormInitialized(true); // Mark form as initialized after setting fields
            };
        } catch (error) {
            console.error("Failed to fetch farm data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (props.show) {
            fetchData();
        }
    }, [props.show]); // Fetch data when modal is shown

    const handleTabletClick = (tablet: TabletDataInterface) => {
        setSelectedTablet(tablet);
        setTabletFormVisible(true);
    };

    const handleVehicleClick = (vehicle: VehicleDataInterface) => {
        setSelectedVehicle(vehicle);
        setVehicleFormVisible(true);
    };

    const handleTabletSave = (updatedTablet: TabletDataInterface) => {
        if (farmData) {
            const updatedTablets = farmData.tablets.map(tablet =>
                tablet.hash_id === updatedTablet.hash_id ? updatedTablet : tablet
            );
            setFarmData({ ...farmData, tablets: updatedTablets });
            form.setFieldsValue({ tablets: updatedTablets });
        }
    };

    const handleVehicleSave = (updatedVehicle: VehicleDataInterface) => {
        if (farmData) {
            const updatedVehicles = farmData.vehicles.map(vehicle =>
                vehicle.hash_id === updatedVehicle.hash_id ? updatedVehicle : vehicle
            );
            setFarmData({ ...farmData, vehicles: updatedVehicles });
            form.setFieldsValue({ vehicles: updatedVehicles });
        }
    };

    const handleAddTablet = () => {
        if (farmData) {
            const newTablet: TabletDataInterface = {
                hash_id: -(farmData.tablets.length + 1), // Set unique negative ID
                name: props.client.name.toLowerCase() + "_" + props.client.surname.toLowerCase() + "_tablet_" + (farmData.tablets.length + 1), // Initial name
                current_agroosa_version: '0.1',
            };
            const updatedTablets = [...farmData.tablets, newTablet];
            setFarmData({ ...farmData, tablets: updatedTablets });
            form.setFieldsValue({ tablets: updatedTablets });
            handleTabletClick(newTablet); // Open form for the new tablet
        }
    };

    const handleRemoveTablet = (tablet: TabletDataInterface) => {
        if (farmData) {
            const updatedTablets = farmData.tablets.filter(t => t.hash_id !== tablet.hash_id);
            setFarmData({ ...farmData, tablets: updatedTablets });
            form.setFieldsValue({ tablets: updatedTablets });
        }
    };

    const handleAddVehicle = () => {
        if (farmData) {
            const newVehicle: VehicleDataInterface = {
                hash_id: -(farmData.vehicles.length + 1), // Set unique negative ID
                name: props.client.name.toLowerCase() + "_" + props.client.surname.toLowerCase() + "_vehicle_" + (farmData.vehicles.length + 1), // Initial name
                outside_hash_id: '',
            };
            const updatedVehicles = [...farmData.vehicles, newVehicle];
            setFarmData({ ...farmData, vehicles: updatedVehicles });
            form.setFieldsValue({ vehicles: updatedVehicles });
            handleVehicleClick(newVehicle); // Open form for the new vehicle
        }
    };

    const handleRemoveVehicle = (vehicle: VehicleDataInterface) => {
        if (farmData) {
            const updatedVehicles = farmData.vehicles.filter(v => v.hash_id !== vehicle.hash_id);
            setFarmData({ ...farmData, vehicles: updatedVehicles });
            form.setFieldsValue({ vehicles: updatedVehicles });
        }
    };

    const resetForm = () => {
        form.resetFields(); // Reset form fields
        setFarmData(null); // Reset farm data
        setFormInitialized(false); // Mark form as not initialized
        fetchData(); // Refetch farm data after reset (if needed)
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const values = await form.validateFields();

            // Convert lon and lat to float numbers
            const lon = parseFloat(values.lon);
            const lat = parseFloat(values.lat);

            // Check if lon and lat are valid numbers
            if (isNaN(lon) || isNaN(lat)) {
                throw new Error('Longitude and Latitude must be valid numbers');
            }

            const updatedFarm = {
                hash_id: farmData?.hash_id,
                name: values.name,
                lon: lon,
                lat: lat,
                tablets: farmData?.tablets,
                vehicles: farmData?.vehicles,
            };
            await updateFarm(updatedFarm); // Call function to update farm data
            fetchData();
            message.success('Farm updated successfully');
        } catch (error: any) {
            console.error('Failed to update farm:', error);
            if (typeof error === 'object' && error.message) {
                message.error(`Failed to update farm: ${error.message}`);
            } else {
                message.error('Failed to update farm:');
            }
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            if (!farmData) {
                throw new Error('Farm data not available');
            }
            await deleteFarm(farmData.hash_id);
            message.success('Farm deleted successfully');
            resetForm();
            props.setShow(false);
        } catch (error) {
            console.error('Failed to delete farm:', error);
            message.error('Failed to delete farm');
        }
        setLoading(false);
    };


    const deleteConfirm = async () => {
        if (!farmData) {
            throw new Error('Farm data not available');
        }
        Modal.confirm({
            icon: <ExclamationCircleFilled />,
            centered: true,
            title: 'Are you sure?',
            content: 'Do you want to remove farm ' + farmData.name + ' and all tablets and vehicles assigned to it?',
            okButtonProps: { className: 'ant-btn-dangerous' },
            onOk() {
                handleDelete();
                props.setShow(false)
            },
            onCancel() {
                props.setShow(false)
            }

        });
    };

    return (
        <Modal
            title={farmData ? `Farm of: ${props.client.name} ${props.client.surname}` : `No farm available for ${props.client.name} ${props.client.surname}`}
            centered
            visible={props.show}
            onOk={() => props.setShow(false)}
            onCancel={() => props.setShow(false)}
            footer={farmData ? [
                <Button key="save" type="primary" onClick={handleSave}>
                    Save
                </Button>,
                <Button key="restore" onClick={resetForm}>
                    Restore
                </Button>,
                <Button key="delete" danger onClick={() => { deleteConfirm() }}>
                    Delete
                </Button>,
            ] : null}
        >
            {loading ? (
                <Spin />
            ) : farmData ? (
                <Form form={form} layout="vertical">
                    <Form.Item label="Name" name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Longitude" name="lon">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Latitude" name="lat">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Tablets" style={{ border: '1px dashed #1890ff', padding: '10px', borderRadius: '5px' }}>
                        <List
                            dataSource={farmData.tablets}
                            renderItem={item => (
                                <List.Item>
                                    <div>
                                        <Button type="link" onClick={() => handleTabletClick(item)}>
                                            {item.name}
                                        </Button>
                                        <Typography style={{ marginLeft: 16 }}>
                                                {item.name}
                                                <CopyOutlined
                                                    style={{ marginLeft: 8 }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(item.name);
                                                        message.success("Copied to clipboard");
                                                    }}
                                                />
                                        </Typography>
                                    </div>
                                    <Button type="link" danger onClick={() => handleRemoveTablet(item)}>
                                        Remove
                                    </Button>
                                </List.Item>
                            )}
                        />
                        <Button type="dashed" onClick={handleAddTablet} block>
                            Add Tablet
                        </Button>
                    </Form.Item>

                    <Form.Item label="Vehicles" style={{ border: '1px dashed #1890ff', padding: '10px', borderRadius: '5px' }}>
                        <List
                            dataSource={farmData.vehicles}
                            renderItem={item => (
                                <List.Item>
                                    <div>
                                    <Button type="link" onClick={() => handleVehicleClick(item)}>
                                        {item.name}
                                    </Button>
                                    { item.hash_id > 0 ? (
                                        <Typography style={{ marginLeft: 16 }}>
                                            {item.outside_hash_id}
                                            <CopyOutlined
                                                style={{ marginLeft: 8 }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(item.outside_hash_id);
                                                    message.success("Copied to clipboard");
                                                }}
                                            />
                                        </Typography>
                                    ) : <div style={{ color: 'red', marginBottom: '24px',  marginLeft: 16 }}>Save to see the ID</div>
                                    }
                                    </div>
                                    <Button type="link" danger onClick={() => handleRemoveVehicle(item)}>
                                        Remove
                                    </Button>
                                </List.Item>
                            )}
                        />
                        <Button type="dashed" onClick={handleAddVehicle} block>
                            Add Vehicle
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <div>
                    <Button style={{ marginLeft: 10 }} onClick={() => {
                        assignFarm(props.client, context, resetForm); // Assigning farm and reseting the form
                    }}>
                        Assign farm automatically
                    </Button>
                </div>
            )}

            {selectedTablet && (
                <TabletEditForm
                    tablet={selectedTablet}
                    visible={tabletFormVisible}
                    onClose={() => setTabletFormVisible(false)}
                    onSave={handleTabletSave}
                />
            )}
            {selectedVehicle && (
                <VehicleEditForm
                    vehicle={selectedVehicle}
                    visible={vehicleFormVisible}
                    onClose={() => setVehicleFormVisible(false)}
                    onSave={handleVehicleSave}
                />
            )}
        </Modal>
    );
};

export default FarmEditForm;
