import { Button, Collapse } from "antd"
import { latLng } from "leaflet"
import { userInterface } from "../types"
import { EditOutlined, ZoomInOutlined, ProductOutlined } from "@ant-design/icons"
import ClientEditForm from "./ClientEditForm"
import { useState } from "react"
import FarmEditForm from "./FarmEditForm"
interface clientPopupProps {
  user: userInterface,
  side?: boolean,
  map: any,
  setEdit?: any,
  setEditFarm?: any,
}
function getStatusName(status: number) {
  return status
}
const ClientTable = (props: clientPopupProps) => {
  return <>
    <table >
      <tr><td>Client:</td><td><b>{props.user.name + ' ' + props.user.surname}</b></td></tr>
      <tr><td>Address:</td><td><b>{props.user.address}</b></td></tr>
      <tr><td>Latitude:</td><td>{props.user.lat}</td></tr>
      <tr><td>Longitude:</td><td>{props.user.lon}</td></tr>
      <tr><td>Phone:</td><td>{props.user.phone}</td></tr>
      <tr><td>Status:</td><td>{getStatusName(props.user.status)}</td></tr>
      <tr><td>Devices:</td><td>{props.user.devices}</td></tr>
      <tr></tr>
      <tr><td colSpan={2}>
        <Button onClick={() => {
          if (props.map)
            props.map.flyTo(latLng(props.user.lat, props.user.lon), 16)

        }}><ZoomInOutlined /> Zoom</Button>

        <Button style={{ marginLeft: 10 }} onClick={() => {
          props.map.closePopup();
          props.setEdit(true)
        }}><EditOutlined /> Edit</Button>

        <Button style={{ marginLeft: 10 }} onClick={() => {
          props.map.closePopup();
          props.setEditFarm(true)
        }}><ProductOutlined /> Farm</Button>
      </td></tr>
    </table>
    {props.user.note.length > 0 ?
      <><br />
        <Collapse items={[
          {
            key: 'note',
            label: 'Notes',
            children: <p>{props.user.note}</p>,
          }
        ]} defaultActiveKey={['note']} /></> : <></>}
  </>
}
export const ClientPopup = (props: clientPopupProps) => {
  const [showNewClient, setShowNewClient] = useState(false);
  const [showFarm, setShowFarm] = useState(false);

  if (props.side) {
    return (
      <div>
        <ClientTable user={props.user} map={props.map} setEdit={setShowNewClient} setEditFarm={setShowFarm}/>
        <ClientEditForm client={props.user} modal={true} show={showNewClient} setShow={setShowNewClient} />
        <FarmEditForm client={props.user} modal={true} show={showFarm} setShow={setShowFarm} />
      </div>
    )
  }
  return (
    <div style={props.user.note.length > 0 ? { width: '400px' } : { width: '300px' }}>
      <ClientTable user={props.user} map={props.map} setEdit={setShowNewClient} setEditFarm={setShowFarm}/>
      <ClientEditForm client={props.user} modal={true} show={showNewClient} setShow={setShowNewClient} />
      <FarmEditForm client={props.user} modal={true} show={showFarm} setShow={setShowFarm} />
    </div>
  )
}
