import { Dataframe, stationListener } from "../types";
import config from '../config.json';
const { WEBSOCKET_SERVER_URL } = config;


export function listenStation(
  mountpoint: string,
  stationListeners: stationListener[],
  setStationListeners: any,
  setOpen: any
) {

  let socket = new WebSocket(WEBSOCKET_SERVER_URL + '/' + localStorage.getItem('token') + '/' + mountpoint);

  socket.onopen = () => {
    let obj_to_push = {
      mountpoint: mountpoint, dataframeCounters:
      {
        1005: new Dataframe('1005'),
        1077: new Dataframe('1077'),
        1087: new Dataframe('1087'),
        1097: new Dataframe('1097'),
        1127: new Dataframe('1127'),
        1230: new Dataframe('1230')
      }, lastUpdatedDataframe: '', ws: socket
    }
    setStationListeners(stationListeners.concat(obj_to_push))
    setOpen(true)
  };
}

export function updateConnection(rtk: stationListener, stationListeners: any, setStationListeners: any, setOpen: any) {

  rtk.ws.onmessage = (event: any) => {
    rtk.ws.send('Ok')
    let data: string[] = event.data.split(',')
    let temp_l = (stationListeners.filter(
      (element: stationListener) =>
        element.mountpoint == rtk.mountpoint)
    );
    if (temp_l.length == 0) {
      rtk.ws.close();
      return
    }
    let temp = temp_l[0]
    temp.lastUpdatedDataframe = data[0];
    switch (data[0]) {
      case "1005":
        temp.dataframeCounters[1005].updateCount(parseInt(data[2]), parseInt(data[1]));
        break;
      case "1077":
        temp.dataframeCounters[1077].updateCount(parseInt(data[3]), parseInt(data[1]));
        break;
      case "1087":
        temp.dataframeCounters[1087].updateCount(parseInt(data[4]), parseInt(data[1]));
        break;
      case "1097":
        temp.dataframeCounters[1097].updateCount(parseInt(data[5]), parseInt(data[1]));
        break;
      case "1127":
        temp.dataframeCounters[1127].updateCount(parseInt(data[6]), parseInt(data[1]));
        break;
      case "1230":
        temp.dataframeCounters[1230].updateCount(parseInt(data[7]), parseInt(data[1]));
        break;
    }

    setStationListeners(
      stationListeners.filter(
        (element: stationListener) =>
          element.mountpoint != rtk.mountpoint)
        .concat(temp))
  };
  rtk.ws.onclose = rtk.ws.onerror = () => {
    setStationListeners(stationListeners.filter((element: stationListener) => element.mountpoint != rtk.mountpoint))
    if (stationListeners.length == 1)
      setOpen(false)
  };

}

