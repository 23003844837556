import { message } from "antd";
import { getData } from "./getRtkBases";
import { farmInterface, userInterface } from "../types";
import config from '../config.json';
const { SERVER_URL } = config;

export const assignFarm = (client: userInterface, context: any, onSuccess: () => void) => {
    let farm: farmInterface = {
        name: "Farm of " + client.name + " " + client.surname,
        lat: client.lat,
        lon: client.lon,
        // created_timestamp: new Date(),
        // disabled: false,
    };

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        body: JSON.stringify({ token: localStorage.getItem('token'), farm: farm })
    };

    fetch(SERVER_URL + "/client/" + client.id + "/farm", requestOptions)
        .then((response) => {
            if (response.ok) {
                getData(context.data.rtkBases[1], context.data.users[1]);
                if (onSuccess) {
                    onSuccess(); // Execute the callback to refresh data
                }
            } else {
                throw new Error("Failed to assign farm");
            }
        })
        .catch((e) => {
            message.error("Could not assign farm");
        });
}
