import { DisconnectOutlined } from "@ant-design/icons"
import { Button, Card, Descriptions } from "antd"
import { Dataframe, stationListener } from "../types"

interface rtkListenerProps {
  station: stationListener,
}
const RtkListener = (props: rtkListenerProps) => {

  return (
    <Card title={props.station.mountpoint}
      type="inner"
      style={{ width: '100%' }}
      extra={<Button onClick={() => {
        props.station.ws.send('stop');
        props.station.ws.close();
      }} icon={<DisconnectOutlined />} />}
    >
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {Object.entries(props.station.dataframeCounters).map((value: [any, Dataframe], index, array: any) => {
          return <Card
            headStyle={{ backgroundColor: props.station.lastUpdatedDataframe == value[1].type ? "#7DCFB6" : '', minHeight: '45px', textAlign: 'center' }}
            type="inner"
            bodyStyle={{ padding: 0 }}
            style={{ margin: '10px', width: '280px' }}
            title={value[1].type}
          >
            <Descriptions column={1} size={'small'} layout="horizontal" bordered>
              <Descriptions.Item label="Count">{value[1].count}</Descriptions.Item>
              <Descriptions.Item label="Last frame length">{value[1].lastLength}</Descriptions.Item>
              <Descriptions.Item label="Last updated">{(new Date(value[1].lastUpdate)).getHours()}:{(new Date(value[1].lastUpdate)).getMinutes()}:{(new Date(value[1].lastUpdate)).getSeconds()}.{(new Date(value[1].lastUpdate)).getMilliseconds()}</Descriptions.Item>
            </Descriptions>
          </Card>
        })}
      </div>
    </Card>
  )
}
export default RtkListener
