import { latLng } from "leaflet"
import { rtkBaseInterface } from "../types"
import { Button } from "antd";
import { listenStation, } from "../adapter/stationListener";
import { WifiOutlined, ZoomInOutlined } from "@ant-design/icons";

interface rtkBasePopupProps {
  value: rtkBaseInterface,
  show: boolean,
  map: any,
  stationListeners: any,
  setStationListeners: any
  setOpen: any
}
const RtkBasePopup = (props: rtkBasePopupProps) => {

  return (
    <table style={{ width: '300px' }}>
      <tr><td>MountPoint:</td><td><b>{props.value.mountpoint}</b></td></tr>
      <tr><td>State:</td><td>{props.value.active == 1 ? "active" : "nonactive"}</td></tr>
      <tr><td>Last change:</td><td>{props.value.updated.toString()}</td></tr>
      <tr><td>Latitude:</td><td>{props.value.lat}</td></tr>
      <tr><td>Longitude:</td><td>{props.value.lon}</td></tr>
      <tr><td colSpan={2}>
        {props.value.active === 1 && props.show === true ?
          <Button style={{ marginRight: 10 }}
            onClick={() => listenStation(props.value.mountpoint, props.stationListeners, props.setStationListeners, props.setOpen)}>
            <WifiOutlined /> Listen</Button> :
          <></>}
        <Button onClick={() => {
          if (props.map)
            props.map.flyTo(latLng(props.value.lat, props.value.lon), 16)
        }}><ZoomInOutlined /> Zoom</Button>
      </td>
      </tr>
    </table>
  )
}

export default RtkBasePopup

