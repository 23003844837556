import { message } from "antd";
import { getData } from "./getRtkBases";
import config from '../config.json';
const { SERVER_URL } = config;


export const deleteClient = (id: number,context:any) => {

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    },
    body: JSON.stringify({ token: localStorage.getItem('token'), id: id })
  };
  fetch(SERVER_URL + "/client/delete", requestOptions).then((response) => {
    getData(context.data.rtkBases[1], context.data.users[1]);
    
  }).catch((e) => {
    message.error("Could not delete client")
  })
}

