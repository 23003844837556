import { Button, Form, Input, InputNumber, Modal, Radio, message } from "antd"
import { userInterface } from "../types"
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteClient } from "../adapter/deleteClient";
import { UseDataSourceContext } from "../DataSourceContext";
import { editClient } from "../adapter/editClient";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { addClient } from "../adapter/addClient";
import { assignFarm } from "../adapter/assignFarm";

interface clientEditFormProps {
    client?: userInterface,
    modal?: boolean,
    setShow?: any,
    show?: boolean
}
const deleteConfirm = (id: number, name: string, context: any, setShow: any) => {
    Modal.confirm({
        icon: <ExclamationCircleFilled />,
        centered:true,
        title: 'Are you sure?',
        content: 'Do you want to remove ' + name + ' from map?',
        okButtonProps: { className: 'ant-btn-dangerous' },
        onOk() {
            deleteClient(id, context);
            setShow(false)
        },
        onCancel() {
            setShow(false)
        }

    });
};

const ClientForm = (props: clientEditFormProps) => {
    const context = UseDataSourceContext();
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(props.client)
    }, [props.show])

    function onFinish(values: userInterface) {
        if (props.client) {
            editClient({ ...values, id: props.client.id }, context)
            if (props.setShow) {
                props.setShow(false)
            }
        } else {
            addClient(values, context);
            if (props.setShow) {
                props.setShow(false)
            }
        }
    }
    const onFinishFailed = (errorInfo: any) => {
        message.error("Client update failed")
    };

    return <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.client ? { ...props.client } : {status:0}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item
            label="Name"
            name="name"

            rules={[{ required: true, message: 'Client name cannot be empty' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Surname"
            name="surname"
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Address"
            name="address"
            
            rules={[{ required: true, message: 'Please input address' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Latitude"
            name="lat"
            rules={[{ required: true, message: 'Please input latitude' }]}
        >
            <InputNumber />
        </Form.Item>
        <Form.Item
            label="Longitude"
            name="lon"
            rules={[{ required: true, message: 'Please input longitude' }]}
        >
            <InputNumber />
        </Form.Item>
        <Form.Item
            label="Phone"
            name="phone"
            rules={[{ pattern: /^(\+[0-9]{2,3}[-\s\.]?)?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}$/, message: 'Not valid phone number' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Status"
            name="status"
        >
            <Radio.Group>
                <Radio.Button value={0}>0</Radio.Button>
                <Radio.Button value={1}>1</Radio.Button>
                <Radio.Button value={2}>2</Radio.Button>
                <Radio.Button value={3}>3</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item
            label="Devices"
            name="devices"
            initialValue={0}
        >
            <InputNumber min={0} />
        </Form.Item>
        <Form.Item
            label="Note"
            name="note"
        >
            <TextArea style={{whiteSpace: "pre-line"}} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" >
                {props.client ? 'Edit' : 'Submit'}
            </Button>
            {props.client ? <>
                <Button style={{ marginLeft: 10 }} onClick={() => form.setFieldsValue(props.client)}>
                    Restore
                </Button>
                <Button style={{ marginLeft: 10 }} onClick={() => { deleteConfirm(props.client!.id, props.client?.name + ' ' + props.client?.surname, context, props.setShow) }} danger>
                    Delete
                </Button>
                {/* <Button style={{ marginLeft: 10 }} onClick={() => assignFarm(props.client!, context)}>
                    Assign farm automatically
                </Button> */}
            </>
                : <></>}
        </Form.Item>
    </Form>


}
const ClientEditForm = (props: clientEditFormProps) => {

    return props.modal ? <> <Modal
        title={props.client ? "Editing client: " + props.client.name + " " + props.client.surname : "New client"}
        centered
        open={props.show}
        onOk={() => props.setShow(false)}
        onCancel={() => props.setShow(false)}
        okText="Cancel"
        destroyOnClose={true}
        okType='default'
        okButtonProps={{ style: { visibility: 'hidden' } }}
        cancelButtonProps={{ style: { visibility: 'hidden' } }}
    >
        <ClientForm client={props.client} show={props.show} setShow={props.setShow} />
    </Modal>
    </> : <ClientForm client={props.client} />
}
export default ClientEditForm