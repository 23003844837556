
import { message, Spin } from 'antd';
import { useEffect, useState } from 'react';
import './App.css';
import LoginPage from './loginPage';
import Map from './map';
import logo from './assets/logo.png'
import { DataSourceContextProvider } from './DataSourceContext';
import config from './config.json';
const { SERVER_URL } = config;

function App() {
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLogged(false);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify({ value: localStorage.getItem('token') })
    };
    if (localStorage.getItem('token'))
      fetch(SERVER_URL + "/check/session", requestOptions).then(() => { setLogged(true); setLoading(false) }).catch((error) => {
        message.error("Session expired")
        localStorage.removeItem('token')
        setLoading(false);
      })
    else
      setLoading(false);
  }, [])
  return (loading ?
    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center' }} id="site">
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        <div id="logo" >
          <img alt="logo" style={{ width: "250px" }} src={logo} />
        </div>
        <Spin /></div>
    </div>
    : logged ?
      <DataSourceContextProvider>
        <Map setLogged={setLogged} setLoading={setLoading} />
      </DataSourceContextProvider>
      :
      <LoginPage setLogged={setLogged} setLoading={setLoading} />)
}

export default App;
