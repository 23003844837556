import { message } from "antd";
import config from '../config.json';
const { SERVER_URL } = config;

export const getFarm = async (clientId: any) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        body: JSON.stringify({ value: localStorage.getItem('token') })
    };

    try {
        const response = await fetch(SERVER_URL + "/client/" + clientId + "/farm/all", requestOptions);
        if (!response.ok) {
            throw new Error("Could not load farm");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        message.error("Couldn't load farm");
    }
};