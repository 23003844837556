import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import { useEffect, useState } from 'react';
import logo from './assets/logo.png'
import logout_img from './assets/logout.svg'
import {  stationListener } from './types';
import { latLng } from 'leaflet';
import L from 'leaflet'
import { ClientPopup } from './map/clientPopup';
import RtkBasePopup from './map/rtkBasePopup';
import ListeningModal from './map/ListeningModal';
import { getData } from './adapter/getRtkBases';
import { logout } from './adapter/logout';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import SideBar from './map/SideBar';
import { updateConnection } from './adapter/stationListener';
import { UseDataSourceContext } from './DataSourceContext';

interface mapProps {
  setLoading: any,
  setLogged: any
}
const Map = (props: mapProps) => {
  let [collapseSiteBar, setCollapseSiteBar] = useState(false);
  let [showMarkers, setShowMarkers] = useState({ clients: false, rtk: true })
  let [filter, setFilter] = useState({ name: '' })
  let [open, setOpen] = useState(false);
  let [stationListeners, setStationListeners] = useState<any[]>([]);
  let [map, setMap] = useState(null)
  const context = UseDataSourceContext();
  useEffect(() => {
    getData(context.data.rtkBases[1], context.data.users[1], props.setLogged)
    const interval = setInterval(() => getData(context.data.rtkBases[1], context.data.users[1], props.setLogged), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    stationListeners.forEach((rtk: stationListener, index: any, array: any) => {
      updateConnection(rtk, stationListeners, setStationListeners, setOpen)
    })
  }, [stationListeners])
  return (
    <>
      <ListeningModal open={open} setOpen={setOpen} stationListeners={stationListeners} />
      <Button type="primary" onClick={() => setOpen(true)} style={{ zIndex: '1', display: stationListeners.length > 0 ? 'block' : 'none', position: 'absolute', bottom: '20px', left: '20px', color: 'black', width: '5vw', height: '5vw', minWidth: '50px', minHeight: '50px' }}><CloudDownloadOutlined style={{ fontSize: '2vw' }} /></Button>
      <div id="map" style={{ zIndex: '0', width: "calc(100% - 50px)", height: "100%", position: "absolute" }}>
        <MapContainer style={{ height: "100%", width: "100%" }} center={[52.255, 19.501]} zoom={7} whenCreated={(instance: any) => setMap(instance)}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {showMarkers.clients ?
            context.data.users[0].map((value, index, array) => {
              if ((filter.name.length === 0 ||
                value.phone.replaceAll(' ', '').toLowerCase().includes(filter.name) ||
                value.note.toLowerCase().includes(filter.name) ||
                value.name.toLowerCase().includes(filter.name) ||
                value.surname.toLowerCase().includes(filter.name) ||
                value.address.toLowerCase().includes(filter.name))) {
                let title = ''
                if (value.status === 0) {
                  title = '<b>⚫️ '
                } else if (value.status === 1) {
                  title = '<b>🔵 '
                } else if (value.status === 2) {
                  title = '<b>🟢 '
                }
                else if (value.status === 3) {
                  title = '<b>🔴 '
                }

                title = title + value.name + ' ' + value.surname + '</b>'
                return (<>
                  <Marker

                    position={latLng(value.lat, value.lon)}
                    icon={L.divIcon({
                      className: 'poi', html: title
                    })}>
                    <Popup>
                      <ClientPopup user={value} map={map} />
                    </Popup>
                  </Marker>
                </>)
              } else
                return (<></>)
            })
            : <></>}
          {showMarkers.rtk ? <>
            {context.data.rtkBases[0].map(value => {
              if (value.active === 1 && (filter.name.length === 0 || value.mountpoint.toLowerCase().includes(filter.name)))
                return (<>
                  <Circle center={[value.lat, value.lon]} pathOptions={{ color: '#fcca1b90', fillColor: '#fcca1b90' }} radius={40000} >
                  </Circle>
                  <Circle center={[value.lat, value.lon]} pathOptions={{ color: '#50af0190', fillColor: '#50af0190' }} radius={20000} >
                  </Circle>
                  <Marker position={latLng(value.lat, value.lon)} icon={L.divIcon({ className: 'poi', html: '<b>✹' + value.mountpoint + '</b>' })}>
                    <Popup>
                      <RtkBasePopup
                        stationListeners={stationListeners}
                        setStationListeners={setStationListeners}
                        show={!stationListeners.some((element, index, array) => value.mountpoint === element.mountpoint)}
                        value={value} map={map} setOpen={setOpen} />
                    </Popup>
                  </Marker>
                </>)
              else
                return (<></>)
            })}
            {context.data.rtkBases[0].map(value => {
              if (value.active === 0 && (filter.name.length === 0 || value.mountpoint.toLowerCase().includes(filter.name)))
                return (<>
                  <Circle center={[value.lat, value.lon]} pathOptions={{ color: '#f7120290', fillColor: '#f7120290' }} radius={20000} >
                  </Circle>
                  <Marker position={latLng(value.lat, value.lon)} icon={L.divIcon({ className: 'poi', html: '<b style="color: #7c0103">✹' + value.mountpoint + '</b>' })}>
                    <Popup>
                      <RtkBasePopup
                        stationListeners={stationListeners}
                        setStationListeners={setStationListeners}
                        show={!stationListeners.some((element, index, array) => value.mountpoint === element.mountpoint)}
                        value={value} map={map} setOpen={setOpen} />
                    </Popup>
                  </Marker>
                </>)
              else
                return (<></>)
            })}</> : <></>}
        </MapContainer>
      </div>
      <div id="site" style={{ zIndex: '1', width: collapseSiteBar ? "50px" : "400px", height: "100%", overflowY: 'scroll', right: "0", position: "fixed", backgroundColor: "white" }}>
        <div onClick={() => setCollapseSiteBar(!collapseSiteBar)} id="hide-arrow" style={{ position: "absolute", height: "70px",width:"30px", margin: "10px", display: "flex" }}>
          <svg style={{ alignSelf: "center", rotate: collapseSiteBar ? '180deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
            viewBox="0 0 16 16">
            <path fillRule="evenodd"
              d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8Zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5Z" />
          </svg>
        </div>
        <div onClick={() => { logout(props.setLogged) }} style={{ display: collapseSiteBar ? 'none' : 'flex', position: "absolute", height: "70px",width:"30px", margin: "10px", right: '0' }} >
          <img alt="layout" src={logout_img} />
        </div>
        <div id="logo" style={{ display: collapseSiteBar ? 'none' : 'block', width: "100%", textAlign: "center" }}>
          <img alt="logo" style={{ width: "250px" }} src={logo} />
        </div>
        {!collapseSiteBar ?
          <SideBar
            rtkBases={context.data.rtkBases[0]}
            setFilter={setFilter}
            filter={filter}
            setShowMarkers={setShowMarkers}
            showMarkers={showMarkers}
            map={map}
            users={context.data.users[0]}
            setOpen={setOpen}
            stationListeners={stationListeners}
            setStationListeners={setStationListeners} /> : <></>}
      </div>
    </>
  );
}
export default Map
function DataSourceContext() {
  throw new Error('Function not implemented.');
}

