import { Button, Collapse, Input, Switch, Tabs } from "antd"
import { CaretRightOutlined, UserAddOutlined } from '@ant-design/icons';
import { rtkBaseInterface, userInterface } from "../types";
import { ClientPopup } from "./clientPopup";
import RtkBasePopup from "./rtkBasePopup";
import ClientEditForm from "./ClientEditForm";
import { useState } from "react";

interface sideBarProps {
  rtkBases: any,
  setFilter: any,
  filter: any,
  setShowMarkers: any,
  showMarkers: any,
  map: any,
  users: userInterface[],
  stationListeners: any,
  setStationListeners: any,
  setOpen: any

}

const SideBar = (props: sideBarProps) => {
  const [showNewClient, setShowNewClient] = useState(false);
  const panelStyleDisabled = {
    marginBottom: 10,
    background: "#e6a71760",
    borderRadius: '8px',
    border: '1px solid red',
  };
  const panelStyleEnabled = {
    marginBottom: 10,
    background: "#e6a71760",
    borderRadius: '8px',
    border: 'none',
  };

  return (
    <div style={{ padding: '10px' }}>
      <Switch style={{ margin: '5px' }} onChange={(v) => props.setShowMarkers({ ...props.showMarkers, rtk: v })} defaultChecked /> Display RTK bases<br />
      <Switch style={{ margin: '5px' }} onChange={(v) => props.setShowMarkers({ ...props.showMarkers, clients: v })} /> Display Clients <br /><br />
      <Input
        style={{ width: '100%' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.setFilter({ name: e.target.value ? e.target.value.toLowerCase() : '' })}
        allowClear
        placeholder="Search"
      />
      {props.showMarkers.clients ? <>
        <br />
        <br />
        <Button icon={<UserAddOutlined />} onClick={() => setShowNewClient(true)}>
          Add client
        </Button>
      </> : <></>}
      <br />
      <br />
      <Tabs
        type="card"
        items={[{
          label: `RTK bases`,
          key: 'rtk',
          children: <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{ background: '#f5c422' }}
            items={props.rtkBases.filter((element: rtkBaseInterface) => props.filter.name.length == 0 || element.mountpoint && element.mountpoint.toLowerCase().includes(props.filter.name)).map((element: rtkBaseInterface, index: any, array: any) => {
              return ({
                key: element.mountpoint,
                label: element.mountpoint,
                children: <RtkBasePopup
                  stationListeners={props.stationListeners}
                  setStationListeners={props.setStationListeners}
                  show={!props.stationListeners.some((element_t: any, index: any, array: any) => element_t.mountpoint === element.mountpoint)}
                  value={element} map={props.map} setOpen={props.setOpen} />,
                style: element.active == 1 ? panelStyleEnabled : panelStyleDisabled,
              })
            })}
          />
        }, {
          label: `Clients`,
          key: 'clients',
          children:
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              style={{ background: '#f5c422' }}
              items={props.users.filter((element: userInterface) => props.filter.name.length === 0 ||
                element.phone.replaceAll(' ', '').toLowerCase().includes(props.filter.name) ||
                element.note.toLowerCase().includes(props.filter.name) ||
                element.name.toLowerCase().includes(props.filter.name) ||
                element.surname.toLowerCase().includes(props.filter.name) ||
                element.address.toLowerCase().includes(props.filter.name)).map((element: userInterface, index: any, array: any) => {
                  return ({
                    key: element.name + element.surname+element.id,
                    label: element.name + ' ' + element.surname,
                    children: <ClientPopup side={true} user={element} map={props.map} />,
                    style: panelStyleEnabled,
                  })
                })}
            />
          ,
        }
        ]}
      />
      <ClientEditForm modal={true} show={showNewClient} setShow={setShowNewClient} />
    </div >
  )
}
export default SideBar
