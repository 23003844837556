import config from '../config.json';
const { SERVER_URL } = config;

export const updateFarm = async (updatedFarmData: any) => {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        body: JSON.stringify({
            farm: updatedFarmData,
            token: localStorage.getItem('token')
        })
    };

    try {
        const response = await fetch(SERVER_URL + "/farm", requestOptions);
        if (!response.ok) {
            const responseBody = await response.text();
            if (responseBody.includes('duplicate key value violates unique constraint "unique_tablet_name"')) {
                throw new Error("Tablet names must be unique");
            } else {
                throw new Error("Failed to update farm");
            }
        }
        // Optionally handle response data if needed
        // const data = await response.json();
        // return data;
    } catch (error) {
        console.error("Failed to update farm:", error);
        throw error; // Propagate error to handle in calling function if needed
    }
};