import config from '../config.json';
const { SERVER_URL } = config;

export const deleteFarm = async (hash_id: any) => {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        body: JSON.stringify({ value: localStorage.getItem('token') })
    };

    try {
        const response = await fetch(SERVER_URL + "/farm/" + hash_id, requestOptions);
        if (!response.ok) {
            throw new Error("Failed to delete farm");
        }
        // Optionally handle response data if needed
        // const data = await response.json();
        // return data;
    } catch (error) {
        console.error("Failed to delete farm:", error);
        throw error; // Propagate error to handle in calling function if needed
    }
};