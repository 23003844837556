import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from "react-dom";
import { ConfigProvider } from 'antd';
ReactDOM.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#E6A717',
      },
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
