
import { Modal } from 'antd';
import { stationListener } from '../types';
import RtkListener from './RtkListener';

interface listeningModalProps {
  open: any, setOpen: any,
  stationListeners: any,
}
const ListeningModal = (props: listeningModalProps) => {
  return (<Modal
    title=""
    centered
    footer={null}
    open={props.open}
    onCancel={() => props.setOpen(false)}
    width={'80%'}
  >
    <br />
    {[...props.stationListeners].sort((obj1: stationListener, obj2: stationListener) => (obj1.mountpoint < obj2.mountpoint) ? -1 : 1).map((element: any, index: any, array: any) => {
      return <RtkListener station={element} />
    }
    )}
  </Modal >
  )
}
export default ListeningModal
